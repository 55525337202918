import React from 'react';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import whatsappImage from '../../Images/413f0fbf04f03406711be8aaf595503c-removebg-preview.png'; // Replace this with the path to your transparent WhatsApp logo

const WhatsAppButton = () => {
  const handleIconClick = () => {
    const whatsappNumber = '9892556170'; // Example number
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
  };

  return (
    <IconButton
      onClick={handleIconClick}
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <img
        src={whatsappImage}
        alt="WhatsApp"
        style={{ width: 100, height: 100, cursor: 'pointer' }}
      />
    </IconButton>
  );
};

export default WhatsAppButton;
