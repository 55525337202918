import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function Customsnackbar({ open, handleClose, message, severity, autoHideDuration }) {
  
  
  useEffect(() => {
    let timer;
    if (open && autoHideDuration) {
      timer = setTimeout(handleClose, autoHideDuration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open, handleClose, autoHideDuration]);

 return (
<div style={{ display: open ? 'flex' : 'none', position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: severity === 'error' ? '#b30000' : 'green', color: 'white', padding: '10px', borderRadius: '5px', alignItems: 'center',width:'auto', }}>
<p style={{ flex: '1', margin: 0 }}>{message}</p>
      <button onClick={handleClose} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', outline: 'none' }}>
        <CloseIcon style={{ color: 'white' }} />
      </button>
    </div>
  );
}

