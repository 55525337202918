import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.navinenterprises.in/api/login', {
        email: formData.email,
        password: formData.password,
      });

      localStorage.setItem('token', response.data.token); // Store token in localStorage
      onLogin(); // Call the onLogin function to update the login state
      navigate('/AddImageToGallery'); // Redirect using useNavigate
    } catch (error) {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#ffffff', // White background
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 3,
          boxShadow: 3,
          backgroundColor: '#ffffff', // White background
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ color: '#FFD700' }} // Gold color
        >
          Login
        </Typography>
        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            value={formData.email}
            onChange={handleChange('email')}
            required
            InputLabelProps={{ sx: { color: '#000000' } }} // Black color for label
            InputProps={{ sx: { borderColor: '#000000' } }} // Black color for border
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={formData.password}
            onChange={handleChange('password')}
            required
            InputLabelProps={{ sx: { color: '#000000' } }} // Black color for label
            InputProps={{ sx: { borderColor: '#000000' } }} // Black color for border
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: '#FFD700', // Gold color
              ':hover': { backgroundColor: '#FFC107' }, // Slightly darker gold for hover
            }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
