import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, TextField } from '@mui/material';
import axios from 'axios';

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingData, setEditingData] = useState({
    imageFile: null,
    title: '',
    description: '',
    image: '',
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch all categories from the backend
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.navinenterprises.in/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Handle category deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.navinenterprises.in/api/categories/${id}`);
      fetchCategories(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  // Handle editing mode setup
  const handleEdit = (category) => {
    if (!category || !category._id) {
      console.error("Category object is missing or ID is null");
      return;
    }

    setEditingId(category._id);
    setEditingData({
      imageFile: null,
      title: category.title || '',
      description: category.description || '',
      image: category.image || '',
    });
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setEditingData({ ...editingData, imageFile: e.target.files[0] });
  };

  // Handle the update of the category
  const handleUpdate = async () => {
    if (!editingId) {
      console.error("Editing ID is null, cannot update category");
      return;
    }

    try {
      const formData = new FormData();
      if (editingData.imageFile) {
        formData.append('image', editingData.imageFile);
      } else if (editingData.image) {
        formData.append('image', editingData.image); // Existing image URL if no new file
      }
      formData.append('title', editingData.title);
      formData.append('description', editingData.description);

      console.log("Updating category with ID:", editingId);
      const response = await axios.put(`https://api.navinenterprises.in/api/categories/${editingId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Update response:", response);

      setEditingId(null);
      setEditingData({
        imageFile: null,
        title: '',
        description: '',
        image: '',
      });
      fetchCategories(); // Refresh the list after updating
    } catch (error) {
      console.error('Error updating category:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Category List</Typography>
      <Grid container spacing={2}>
        {categories.length === 0 ? (
          <Typography>No categories available</Typography>
        ) : (
          categories.map((category) => (
            <Grid item xs={12} sm={6} md={4} key={category._id}>
              <Box
                sx={{
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                  backgroundColor: 'white',
                  border: '2px solid gold',
                  height: '100%',
                }}
              >
                {editingId === category._id ? (
                  <>
                    <TextField
                      label="Title"
                      value={editingData.title}
                      onChange={(e) => setEditingData({ ...editingData, title: e.target.value })}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="Description"
                      value={editingData.description}
                      onChange={(e) => setEditingData({ ...editingData, description: e.target.value })}
                      fullWidth
                      multiline
                      rows={4}
                      sx={{ mb: 2 }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ marginBottom: '16px' }}
                    />
                    {editingData.image && (
                      <img
                        src={`https://api.navinenterprises.in/${editingData.image}`}
                        alt="Current"
                        style={{ width: '100%', height: 'auto', marginBottom: '16px' }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, ml: 2 }}
                      onClick={() => setEditingId(null)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {category.title}
                    </Typography>
                    <Typography sx={{ mb: 2 }}>{category.description}</Typography>
                    {category.image && (
                      <img
                        src={`https://api.navinenterprises.in/${category.image}`}
                        alt={category.title}
                        style={{ width: '100%', height: 'auto', marginBottom: '16px' }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleEdit(category)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, ml: 2 }}
                      onClick={() => handleDelete(category._id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}

export default CategoryList;
