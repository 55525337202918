import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Hidden } from "@mui/material";
import QuickEnquiry from "../../Forms/QuickEnquiry";
import { Helmet } from "react-helmet";
import header from "../../../Images/kaleb-tapp-J59wWPn09BE-unsplash.jpg";

function Contact() {
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setAnimateText(true);
      } else {
        setAnimateText(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ overflow: "hidden", marginTop: "10vmin" }}>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>

      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px",
          color: "white",
          marginBottom: "-40px",
          height: "100vh",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${header})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.5,
            zIndex: -1,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: -1,
          },
        }}
      >
        <Box style={{ paddingTop: "200px" }}>
          <Typography sx={{ fontSize: "8vmin", fontWeight: "bold", textAlign: "left" }}>
            Contact Us
          </Typography>
          <Hidden smDown>
            <Box padding={"10px 300px 20px 6px"}>
              <hr />
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box padding={"10px 40px 20px 40px"}>
              <hr />
            </Box>
          </Hidden>
          <Typography sx={{ fontSize: "4vmin", textAlign: "left" }}>
            Here at Navin Enterprises, we specialise in commercial and industrial plating solutions.
          </Typography>
        </Box>
      </Box>

      <Container sx={{ paddingTop: 4 }}>
        <Box>
          <Typography sx={{ fontSize: "3vmin", padding: "50px", textAlign: "center" }}>
            There are a bunch of ways you can make contact with the team here at Navin. The easiest way is to pick up the phone and call us on our freephone number: 0333 121 0151. You’ll speak to one of our friendly and professional staff who will happily answer any of your questions and provide you with a free, <br /> no-obligation quote.
            Alternatively, you can send us an email at enquiries@Navin.com, or complete the form below. All electronic messages are checked throughout the day, and a member of our team will be in touch with you as soon as possible.
          </Typography>
        </Box>

        <Typography variant="h4" align="center" sx={{ padding: "20px 0", fontWeight: "bold" }}>Get In Touch</Typography>

        <Grid container spacing={4} justifyContent="center" alignItems="flex-start" textAlign="center" sx={{ padding: '0 80px 80px' }}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                padding: "20px",
                borderRadius: "8px",
                minHeight: "250px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "rotate(-3deg) scale(1.05)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingBottom: "6px",
                }}
              >
                ADDRESS
              </Typography>
              <Typography textAlign="left" paddingTop="10px">
                C-18, Adiwasi Pada, <br /> Mandal Compound, Road No 34,<br /> Wagle Industrial Estate,<br /> Thane West, Maharashtra 400604
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                padding: "20px",
                borderRadius: "8px",
                minHeight: "250px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "rotate(-3deg) scale(1.05)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingBottom: "6px",
                }}
              >
                EMAIL ADDRESS
              </Typography>
              <Typography textAlign="left" paddingTop="10px">
                navinplating@gmail.com
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                padding: "20px",
                borderRadius: "8px",
                minHeight: "250px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "rotate(-3deg) scale(1.05)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingBottom: "6px",
                }}
              >
                PHONE
              </Typography>
              <Typography textAlign="left" paddingTop="10px">
                +91 98925 56170
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <QuickEnquiry />
      </Container>
    </div>
  );
}

export default Contact;
