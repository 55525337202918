import './App.css';
import React, { useState, useEffect } from 'react';
import Routers from './Components/Navigation/Routers';
import InquiryFormModal from './Components/Forms/InquiryFormModal'

function App() {

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const delayTime = 4000;
    const timerId = setTimeout(() => {
      setModalOpen(true);
    }, delayTime);

    return () => clearTimeout(timerId);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
         <InquiryFormModal isOpen={isModalOpen} onClose={closeModal} />
      <Routers/> 
     
    </div>
  );
}

export default App;
