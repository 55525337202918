import React from 'react';
import { Container, Grid, Typography, Box, Hidden } from '@mui/material';
import image1 from "../../../Images/metal.jpg";
import image2 from "../../../Images/electroplating.jpg";
import header from "../../../Images/albert-stoynov-PwVw_V8pKKc-unsplash.jpg"
import CardAboutUs from './SubComps/CardAboutUs';

const AboutUsPage = () => {
    return (
        <>
            {/* Header Section with Image */}
            <Box
                sx={{
                    position: "relative",
                    textAlign: "center",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: "100px",
                    color: "white",
                    height: "100vh",
                    width: "100%",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${header})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        opacity: 0.7,
                        zIndex: -1,
                    },
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "black",
                        opacity: 0.5,
                        zIndex: -1,
                    },
                }}
            >
                <Box style={{ paddingTop: "250px" }}>
                    <Typography sx={{ fontSize: "8vmin", fontWeight: "bold", textAlign: "left" }}>
                        About Us
                    </Typography>
                    <Hidden smDown>
                        <Box padding={"10px 300px 20px 6px"}>
                            <hr />
                        </Box>
                    </Hidden>
                    <Hidden smUp>
                        <Box padding={"10px 40px 20px 40px"}>
                            <hr />
                        </Box>
                    </Hidden>
                    <Typography sx={{ fontSize: "4vmin", textAlign: "left" }}>
                        Here at Navin Enterprises, we specialise in commercial and industrial plating solutions.
                    </Typography>
                </Box>
            </Box>

            <Container>
                <Box sx={{ padding: "20px" }}>
                    <Typography variant="h5" sx={{ padding: "10px", color: "black", fontWeight: "bold", fontSize: "7vmin" }}>
                        Our Journey!
                    </Typography>
                    <Typography sx={{ padding: "20px", fontSize: "3.5vmin" }}>
                        'NAVIN ENTERPRISES' earlier also known as NAVIN PLATING WORKS, is an experienced industrial plating company that has been
                        providing innovative metal finishing solutions since 1996. Our plating company was founded on offering quality metal finishing services
                        in a timely, cost-effective manner.
                    </Typography>
                </Box>

                <CardAboutUs />
            </Container>

            {/* <About /> */}
             {/* Plating Services Section */}
      <Container sx={{ marginTop: 4 }}>
                    {/* Vector Instruments Overview Section */}
                    <Grid container spacing={3} marginBottom={"5%"}>
                        {/* Image Section */}
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    src={image1}
                                    alt="Vector Instruments"
                                    style={{
                                        width: "80%",
                                        height: "auto",
                                        borderRadius: "8px", // Adding borderRadius to match the design
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Text Section */}
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    p: "20px 30px 30px 50px",
                                    textAlign: "left",
                                    backgroundColor: "black", // Changed background color to black
                                    color: "white", // Changed text color to white
                                    borderRadius: "8px", // Adjusting borderRadius to match the image
                                    height: "100%",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{ fontWeight: "bold", fontSize: "5vmin" }}
                                >
                                    Metals
                                </Typography>
                                <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                                    *Metals* are the raw materials used as coatings in the plating process. They provide fundamental properties such as hardness, corrosion
                                    resistance, or aesthetic appeal. Examples include nickel, chrome, gold, and silver, which are chosen based on the desired characteristics of
                                    the final product.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Strategic Alliances Section */}
                    <Grid container spacing={3} marginBottom={"5%"}>
                        <Hidden smUp>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img
                                        src={image2}
                                        alt="Strategic Alliances"
                                        style={{
                                            width: "80%",
                                            height: "auto",
                                            borderRadius: "8px", // Adding borderRadius to match the design
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Hidden>
                        {/* Text Section */}
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    p: "20px 30px 30px 50px",
                                    textAlign: "left",
                                    backgroundColor: "black", // Changed background color to black
                                    color: "white", // Changed text color to white
                                    borderRadius: "8px", // Adjusting borderRadius to match the image
                                    height: "100%",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{ fontWeight: "bold", fontSize: "5vmin" }}
                                >
                                    Electroplating
                                </Typography>
                                <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                                    *Electroplating* is the technique used to apply these metal coatings. It involves immersing the object in a solution containing metal salts
                                    and applying an electric current to deposit a thin layer of metal onto the substrate. This process imparts specific properties to the surface,
                                    such as improved wear resistance, enhanced appearance, or added corrosion protection.
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Image Section */}
                        <Hidden smDown>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img
                                        src={image2}
                                        alt="Strategic Alliances"
                                        style={{
                                            width: "80%",
                                            height: "auto",
                                            borderRadius: "8px", // Adding borderRadius to match the design
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>

                    {/* Laboratory Products Section */}

                </Container>
        </>
    );
};

export default AboutUsPage;
