import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

function About() {
  const [subcategories, setSubcategories] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(
          "https://api.navinenterprises.in/api/subcategories"
        );
        setSubcategories(response.data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, []);

  const toggleReadMore = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div style={{ overflow: "hidden", marginTop: "10vmin" }}>
      <Helmet>
        <title>Services</title>
      </Helmet>

      <Box sx={{ padding: "60px 20px", backgroundColor: "#f4f4f4" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          What Navin Plating can do for you
        </Typography>
        <Typography padding={"0px 20vmin 0px 20vmin"}>
          Navin Plating is a name you can rely upon for all of your metal
          finishing, surface coating, and electroplating needs. We provide an
          extensive range of processes, each one performed by our
          fully-accredited and highly-trained staff.
          <br />
          Here are some of our most popular processes. Simply click on the
          button for more detailed information.
        </Typography>
      </Box>

      <Box sx={{ padding: "20px 30px 10px 30px" }}>
        <Grid container spacing={4}>
          {subcategories.map((subcategory) => (
            <Grid item xs={12} sm={6} md={4} key={subcategory._id}>
              <Box
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  textAlign: "left",
                  backgroundColor: "white",
                  border: "2px solid gold",
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                {subcategory.image && (
                  <img
                    src={`https://api.navinenterprises.in/${subcategory.image}`}
                    alt={subcategory.title}
                    style={{
                      width: "100%",
                      height: "200px", // Fixed height for uniform size
                      objectFit: "cover", // Ensures image covers the area
                      marginBottom: "16px",
                    }}
                  />
                )}
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    {subcategory.title}
                  </Typography>
                  <Typography
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: expanded[subcategory._id] ? "none" : 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {subcategory.description}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    onClick={() => toggleReadMore(subcategory._id)}
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      width: "100%", 
                      mb: 1, 
                    }}
                  >
                    {expanded[subcategory._id] ? "Read Less" : "Read More"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "gold",
                      color: "black",
                      width: "100%", // Full-width button
                    }}
                    component={Link}
                    to={`/subcategory/${subcategory._id}`}
                  >
                    View Details
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default About;
