import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Button, Grid } from "@mui/material";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import quality from '../../../../Images/quality.png'

function ServicePage() {
  const { id } = useParams(); // Get the ID from the URL
  const [subcategory, setSubcategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubcategory = async () => {
      try {
        const response = await axios.get(`https://api.navinenterprises.in/api/subcategories/${id}`);
        setSubcategory(response.data);
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching subcategory:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategory();
  }, [id]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;
  if (!subcategory) return <Typography>No subcategory found</Typography>;

  return (
    <>
      <Box
        sx={{
          paddingTop: "100px",
          width: "100%",
          margin: 0,
          overflowX: "hidden",
          backgroundColor: "white",
          color: "#000",
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            mb: 4,
            overflow: "hidden",
            borderRadius: "8px",
          }}
        >
          <img
            src={`https://api.navinenterprises.in/${subcategory.image}`}
            alt={subcategory.title}
            style={{
              width: "60%",
              height: "40%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </Box>

        {/* Title and Description */}
        <Container
          maxWidth="lg"
          sx={{
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: "white",
            mb: 4,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2, color: "black" }}>
            {subcategory.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {subcategory.description}
          </Typography>
        </Container>

        {/* Questions and Answers Section */}
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            mb: 4,
            mx: "auto",
            maxWidth: "lg",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2, color: "black" }}>
            What is {subcategory.title}?
          </Typography>

          <hr
            style={{
              border: "0",
              height: "1.5px",
              backgroundColor: "#ccc",
              margin: "20px 0",
            }}
          />

          <Typography variant="body1">
            {subcategory.answer}
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item lg={9}>
          <Box sx={{ backgroundColor: "white", color: "black", textAlign: "left", padding: "30px" }}>
            <Typography sx={{ fontSize: "5vmin", padding: "10px", fontWeight: "bold" }}>A Quality Process</Typography>
            <Typography sx={{ fontSize: "2.5vmin", padding: "10px" }}>Just like the rest of our metal finishing techniques, all silver electroplating processes at Navin Enterprises are carried out in compliance with standards for Quality and standards for Environmental. What this means for you, our customers, is plated components of the very highest possible quality.</Typography>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <img src={quality} alt="quality process" />
        </Grid>

      </Grid>

      <Box sx={{ backgroundColor: "black", color: "white", textAlign: "left", padding: "30px" }}>
        <Typography sx={{ fontSize: "7vmin", padding: "10px", fontWeight: "bold" }}>GET IN TOUCH</Typography>
        <Typography sx={{ fontSize: "3.5vmin", padding: "10px" }}>For more information on our high-quality processes, call Navin Enterprises today on <br />
          +91 98925 56170
          to discuss your requirements, and to receive a free, no-obligation quote.</Typography>
        <Button
          variant="outlined"
          sx={{
            borderColor: "white",
            color: "white",
            backgroundColor: "transparent",
            borderWidth: "2px",
            padding: "12px 24px",
            margin: '10px',
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderColor: "white",
            },
          }}
        >
          <NavLink
            to="/contact-us"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Contact Us
          </NavLink>
        </Button>
      </Box>
    </>
  );
}

export default ServicePage;
