import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import CategoryList from './CategoryList'

function AddCategory() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to include the file and other data
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const response = await axios.post('https://api.navinenterprises.in/api/categories', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Category added:', response.data);
      setTitle('');
      setDescription('');
      setImageFile(null);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  return (
   <>
    <Box sx={{ paddingTop: '120px', maxWidth: '600px', margin: '0 auto' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Add New Category</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Category Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          required
        />
        <TextField
          label="Category Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 2 }}
          required
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ marginBottom: '16px' }}
        />
        <Button type="submit" variant="contained" color="primary">Add Category</Button>
      </form>
    </Box>
    <CategoryList/>

   </>
  );
}

export default AddCategory;
