import React, { useState, useEffect, useRef } from "react";
import { Typography, Stack, Button, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import navin from "../../../../Images/WhatsApp Image 2024-08-30 at 2.39.10 PM.jpeg"

const cardData = [
  { title: "Employees with 100+ R&D", description: "We have over 500 dedicated employees.", path: "/employees" },
  { title: "Countries sales network", description: "Our sales network spans over 140 countries.", path: "/countries" },
  { title: "Industries reached", description: "We have reached over 10 industries worldwide.", path: "/industries" },
];

const solutionBoxStyle = {
  padding: "1%",
  textAlign: "center",
  border: "2px solid black",
  backgroundColor: "transparent",
  margin: "20px",
  opacity: 0,
  transform: "translateY(100px)",
  transition: "opacity 1s ease-out, transform 1s ease-out",
};

const useIntersection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return [isVisible, elementRef];
};

function WhatisPOS() {
  const [isVisible, elementRef] = useIntersection();
  const navigate = useNavigate();

  return (
    <>
      {/* <div ref={elementRef} style={{ padding: "0px 0px 20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              width: "100%",
              backgroundColor: "white",
              padding: "60px",
            }}
          >
            {cardData.map((data, index) => (
              <Stack
                key={index}
                alignItems={"center"}
                style={{ ...solutionBoxStyle, ...(isVisible ? { opacity: 1, transform: "translateY(0)" } : {}) }}
              >
                <Typography style={{ fontSize: "4vmin", fontWeight: "bold", color: "black" }}>
                  {data.title}
                </Typography>
                <Typography style={{ fontSize: "2vmin", color: "black" }}>
                  {data.description}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "black",
                    color: "black",
                    backgroundColor: "transparent",
                    borderWidth: "2px",
                    padding: "4px 8px",
                    fontSize: "0.6rem",
                    '&:hover': {
                      backgroundColor: "rgba(15, 90, 142, 0.1)",
                      borderColor: "#0F5A8E",
                    },
                    marginTop: "10px",
                  }}
                  onClick={() => navigate(data.path)}
                >
                  Learn More
                </Button>
              </Stack>
            ))}
          </div>
        </div>
      </div> */}

      <Grid container  sx={{ width: "100%", marginTop: "20px", display: "flex", alignItems: "stretch" }}>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "black", padding: "40px", display: "flex", flexDirection: "column", justifyContent: "center",color:"white" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
              padding: "0px 10px 0px 10px",
            }}
          >
            Why Choose Us ?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "10px",
              textAlign: "left",
              padding: "20px",
            }}
          >
            We acknowledge the challenges you face in finding a reliable plating partner, we are confident in our ability to offer you effective solutions that meet your needs.
          </Typography>
          <ul style={{  textAlign: "left", padding: "0px 30px 10px 30px" }}>
            <li>Experts on hand to ensure you always have the correct plating solution defined, in advance of processing your parts.</li>
            <li>Full capability to support prototypes, trials, and validations with necessary industry qualification, such as PSW / PPAP.</li>
            <li>High level of flexibility across our range of finishes, we manage high and low volumes, and can build (in-house) specific process lines to suit most applications.</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src={navin}
            alt="Why Choose Navin"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WhatisPOS;
