import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box component="footer" sx={{ backgroundColor: 'black', color: 'white', py: 6, opacity: "revert" }}>
      <Container>
        <Grid container spacing={4}>
          {/* Logo and Address */}
          <Grid item xs={12} md={6}>
            <Typography style={{ textAlign: "left", fontSize: "3.5vmin", fontWeight: "bold" }}>
              <Button
                component="span" // Change to a span to avoid showing the URL
                style={{
                  fontSize: "6vmin",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                  minWidth: "auto",
                  minHeight: "auto",
                  lineHeight: 1,
                  textTransform: "none",
                }}
              >
                <NavLink
                  to="/Login"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  Navin Enterprises
                </NavLink>
              </Button>
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '1.2rem', mt: 2 }}>
              C-18, Adiwasi Pada, <br /> Mandal Compound, Road No 34,<br /> Wagle Industrial Estate,<br /> Thane West, Maharashtra 400604
              <br />
              Phone : +91 98925 56170 <br />
              Email:  navinplating@gmail.com
            </Typography>
            {/* Social Media Icons */}
            <Box sx={{ mt: 2 }}>

              <IconButton href="https://www.facebook.com" target="_blank" sx={{ color: 'white' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.youtube.com" target="_blank" sx={{ color: 'white' }}>
                <YouTubeIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/company/navin-enterprises/" target="_blank" sx={{ color: 'white' }}>
                <LinkedInIcon />
              </IconButton>

            </Box>
          </Grid>

          {/* Footer Links */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {/* Add links here if needed */}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ fontSize: '1.2rem', textAlign: 'left', mb: 1 }}>
                  Company
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem', mt: 1, textAlign: 'left' }}>
                  <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'block', paddingTop: '6px' }}>Home</NavLink><br />
                  <NavLink to="/Aboutus" style={{ textDecoration: 'none', color: 'inherit', display: 'block', paddingTop: '2px' }}>About Us</NavLink><br />
                  <NavLink to="/contact-us" style={{ textDecoration: 'none', color: 'inherit', display: 'block', paddingTop: '2px' }}>Contact Us</NavLink>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ fontSize: '1.2rem', textAlign: 'left', mb: 1 }}>
                  Others
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem', mt: 1, textAlign: 'left' }}>
                  <NavLink to="/products" style={{ textDecoration: 'none', color: 'inherit', display: 'block', paddingTop: '6px' }}>Services</NavLink><br />
                  <NavLink to="/gallery" style={{ textDecoration: 'none', color: 'inherit', display: 'block', paddingTop: '2px' }}>Gallery</NavLink><br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Divider */}
        <Box sx={{ mt: 4, mb: 2 }}>
          <Divider sx={{ backgroundColor: 'white' }} />
        </Box>

        {/* Copyright and Scroll to Top */}
        <Box sx={{ textAlign: 'center', marginBottom: "-30px" }}>
          <Typography variant="h6" color="white">
            &copy; 2024 Diginext Marketing. All rights reserved.
          </Typography>
          {/* Uncomment if you want the scroll to top button */}
          {/* <IconButton color="primary" onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
            <Typography variant="body2" sx={{ ml: 1 }}>Go back to the top</Typography>
          </IconButton> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
