import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

function SubcategoryPage() {
  const { categoryId } = useParams(); // Get the category ID from the URL params
  const [subcategories, setSubcategories] = useState([]);
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`https://api.navinenterprises.in/api/subcategories/category/${categoryId}`);
        setSubcategories(response.data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [categoryId]);

  const handleReadMore = (subcategoryId) => {
    navigate(`/subcategory/${subcategoryId}`);
  };

  return (
    <div style={{ overflow: "hidden", marginTop: "10vmin" }}>
      <Helmet>
        <title>Subcategories</title>
      </Helmet>

      <Box sx={{ padding: "60px 20px", backgroundColor: "#f4f4f4" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
        What Navin Plating can do for you        </Typography>
        <Typography padding={"0px 20vmin 0px 20vmin"}>
        Navin Plating is a name you can rely upon for all of your metal finishing, surface coating, and electroplating needs. We provide an extensive range of processes, each one performed by our fully-accredited and highly-trained staff.
              <br />
Here are some of our most popular processes. Simply click on the button for more detailed information.        </Typography>
      </Box>

      <Box sx={{ padding: "20px 30px 10px 30px" }}>
        <Grid container spacing={4}>
          {subcategories.map((subcategory) => (
            <Grid item xs={12} sm={6} md={4} key={subcategory._id}>
              <Box
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  textAlign: "left",
                  backgroundColor: "white",
                  border: "2px solid gold",
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                {subcategory.image && (
                  <img
                    src={`https://api.navinenterprises.in/${subcategory.image}`}
                    alt={subcategory.title}
                    style={{ width: "100%", height: "auto", marginBottom: "16px" }}
                  />
                )}
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  {subcategory.title}
                </Typography>
                <Typography>{subcategory.description}</Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "gold", color: "black" }}
                  onClick={() => handleReadMore(subcategory._id)}
                >
                  Read More
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default SubcategoryPage;
