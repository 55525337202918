import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export const Gallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.navinenterprises.in/api/getAllImages');
        const formattedImages = response.data.map(image => ({
          src: image.headerImage,
          alt: image.headerImage.split('/').pop() // Using the image filename as alt text
        }));
        setImages(formattedImages);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Error fetching images');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Define the pattern for the layout
  const pattern = [3, 2]; // 3 images in the first row, 2 images in the second row

  // Create rows from images based on the pattern
  const rows = [];
  let index = 0;

  while (index < images.length) {
    pattern.forEach((count) => {
      rows.push(images.slice(index, index + count));
      index += count;
    });
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Box
      sx={{
        padding: '80px',
        paddingTop: '130px',
        maxWidth: '1320px',
        margin: '0 auto'
      }}
    >
         <Helmet>
        <title>Gallery</title>
      </Helmet>
      <Grid container spacing={2}>
        {rows.map((row, rowIndex) => (
          rowIndex % 2 === 1 ? (
            <Grid container key={rowIndex} spacing={2}>
              {row.map((image, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    style={{
                      padding: '60px',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container key={rowIndex} spacing={2}>
              {row.map((image, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '200px',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  );
};
