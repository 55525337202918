import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

function About() {
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    // Fetch categories from the backend
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://api.navinenterprises.in/api/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const toggleReadMore = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div style={{ overflow: "hidden", marginTop: "0vmin" }}>
   

      <Box sx={{ padding: "60px 20px", backgroundColor: "#f4f4f4" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          What is Plating?
        </Typography>
        <Typography padding={"0px 20vmin 0px 20vmin"}>
          Plating is the process of depositing a thin layer of metal onto the surface of a substrate. This process is used to improve the appearance, durability, and corrosion resistance of the product. Plating can be done with various metals, including gold, silver, nickel, and chrome.
        </Typography>
      </Box>

      <Box sx={{ padding: "20px 30px 10px 30px" }}>
        <Grid container spacing={4}>
          {categories.map((category) => (
            <Grid item xs={12} sm={6} md={4} key={category._id}>
              <Box
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  textAlign: "left",
                  backgroundColor: "white",
                  border: "2px solid gold",
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {category.image && (
                  <img
                    src={`https://api.navinenterprises.in/${category.image}`}
                    alt={category.title}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      marginBottom: "16px",
                    }}
                  />
                )}
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  {category.title}
                </Typography>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: expanded[category._id] ? "none" : 4,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                  }}
                >
                  {category.description}
                </Typography>
                <Button
                  onClick={() => toggleReadMore(category._id)}
                  sx={{
                    mt: 2,
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  {expanded[category._id] ? "Read Less" : "Read More"}
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "gold", color: "black" }}
                  component={Link}
                  to={`/category/${category._id}/subcategories`}
                >
                  View Subcategories
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default About;
