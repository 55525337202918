import React from 'react'
import { Helmet } from 'react-helmet'
import { Container,Box,Typography,Hidden } from '@mui/material'
import header from "../../../Images/services.jpg"
import OurProducts from './SubComps/OurProducts'

function Products() {
  return (
    <div paddingTop={"90px"}>
       <Helmet>
        <title>Services | ...</title>
      </Helmet>
      <Box
                sx={{
                    position: "relative",
                    textAlign: "center",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: "100px",
                    color: "white",
                    height: "100vh",
                    width: "100%",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${header})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        opacity: 0.7,
                        zIndex: -1,
                    },
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "black",
                        opacity: 0.5,
                        zIndex: -1,
                    },
                }}
            >
                <Box style={{ paddingTop: "300px" }}>
                    <Typography sx={{ fontSize: "8vmin", fontWeight: "bold", textAlign: "left" }}>
                        Our Services
                    </Typography>
                    <Hidden smDown>
                        <Box padding={"10px 300px 20px 6px"}>
                            <hr />
                        </Box>
                    </Hidden>
                    <Hidden smUp>
                        <Box padding={"10px 40px 20px 40px"}>
                            <hr />
                        </Box>
                    </Hidden>
                    <Typography sx={{ fontSize: "4vmin", textAlign: "left" }}>
                    Essential metal finishing and plating processes                    </Typography>
                </Box>
            </Box>

    <OurProducts/>

    
    

    </div>
  )
}

export default Products
