import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div style={{ marginTop: "0vmin" }}>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <Box sx={{ padding: "40px 20px" }}>
        <Grid container spacing={2} justifyContent="center">
          {/* Left Box */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: "20px",
                textAlign: "center",
                borderRadius: "8px",
                width: "100%",
                border:'2px gold solid',
                height: "250px", // Ensures the box is square
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography fontSize={"5vmin"} fontWeight={"bold"}>Quality Metal Finishing</Typography>
              <Typography variant="body" sx={{ mt: 2 }}>
                NAVIN ENTERPRISES, earlier known as NAVIN PLATING WORKS, has
                been providing innovative metal finishing solutions since 1996.
              </Typography>
            </Box>
          </Grid>

          {/* Middle Box */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: "20px",
                textAlign: "center",
                border:'2px gold solid',
                borderRadius: "8px",
                width: "100%",
                height: "250px", // Ensures the box is square
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography fontSize={"5vmin"} fontWeight={"bold"}>Eco-Friendly Processes</Typography>
              <Typography variant="body" sx={{ mt: 2 }}>
                We offer quality metal finishing services in a timely,
                cost-effective manner and are one of the most eco-friendly metal
                plating companies today.
              </Typography>
            </Box>
          </Grid>

          {/* Right Box */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: "20px",
                textAlign: "center",
                borderRadius: "8px",
                width: "100%",
                height: "250px", // Ensures the box is square
                display: "flex",
                flexDirection: "column",
                border:'2px gold solid',
                justifyContent: "center",
              }}
            >
              <Typography fontSize={"5vmin"} fontWeight={"bold"}>Customer Relationships</Typography>
              <Typography variant="body" sx={{ mt: 2 }}>
                We strive to improve our quality of work and grow our business
                to better serve our customers' needs, valuing customer
                relationships as a key part of our success.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
