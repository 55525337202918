import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, TextField } from '@mui/material';
import axios from 'axios';

function SubcategoryList() {
  const [subcategories, setSubcategories] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingData, setEditingData] = useState({
    imageFile: null,
    title: '',
    description: '',
    whatIs: '',
    answer: '',
    image: '',
  });

  useEffect(() => {
    fetchSubcategories();
  }, []);

  // Fetch all subcategories from the backend
  const fetchSubcategories = async () => {
    try {
      const response = await axios.get('https://api.navinenterprises.in/api/subcategories');
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  // Handle subcategory deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.navinenterprises.in/api/subcategories/${id}`);
      fetchSubcategories(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting subcategory:', error);
    }
  };

  // Handle editing mode setup
  const handleEdit = (subcategory) => {
    if (!subcategory || !subcategory._id) {
      console.error("Subcategory object is missing or ID is null");
      return;
    }
    
    setEditingId(subcategory._id);
    setEditingData({
      imageFile: null,
      title: subcategory.title || '',
      description: subcategory.description || '',
      whatIs: subcategory.whatIs || '',
      answer: subcategory.answer || '',
      image: subcategory.image || '',
    });
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setEditingData({ ...editingData, imageFile: e.target.files[0] });
  };

  // Handle the update of the subcategory
  const handleUpdate = async () => {
    if (!editingId) {
      console.error("Editing ID is null, cannot update subcategory");
      return;
    }

    try {
      const formData = new FormData();
      if (editingData.imageFile) {
        formData.append('image', editingData.imageFile);
      } else if (editingData.image) {
        formData.append('image', editingData.image); // Existing image URL if no new file
      }
      formData.append('title', editingData.title);
      formData.append('description', editingData.description);
      formData.append('whatIs', editingData.whatIs);
      formData.append('answer', editingData.answer);

      console.log("Updating subcategory with ID:", editingId);
      const response = await axios.put(`https://api.navinenterprises.in/api/subcategories/${editingId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Update response:", response);

      setEditingId(null);
      setEditingData({
        imageFile: null,
        title: '',
        description: '',
        whatIs: '',
        answer: '',
        image: '',
      });
      fetchSubcategories(); // Refresh the list after updating
    } catch (error) {
      console.error('Error updating subcategory:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Subcategory List</Typography>
      <Grid container spacing={2}>
        {subcategories.length === 0 ? (
          <Typography>No subcategories available</Typography>
        ) : (
          subcategories.map((subcategory) => (
            <Grid item xs={12} sm={6} md={4} key={subcategory._id}>
              <Box
                sx={{
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                  backgroundColor: 'white',
                  border: '2px solid gold',
                  height: '100%',
                }}
              >
                {editingId === subcategory._id ? (
                  <>
                    <TextField
                      label="Title"
                      value={editingData.title}
                      onChange={(e) => setEditingData({ ...editingData, title: e.target.value })}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="Description"
                      value={editingData.description}
                      onChange={(e) => setEditingData({ ...editingData, description: e.target.value })}
                      fullWidth
                      multiline
                      rows={4}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="What Is"
                      value={editingData.whatIs}
                      onChange={(e) => setEditingData({ ...editingData, whatIs: e.target.value })}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="Answer"
                      value={editingData.answer}
                      onChange={(e) => setEditingData({ ...editingData, answer: e.target.value })}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ marginBottom: '16px' }}
                    />
                    {editingData.image && (
                      <img
                        src={`https://api.navinenterprises.in/${editingData.image}`}
                        alt="Current"
                        style={{ width: '100%', height: 'auto', marginBottom: '16px' }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, ml: 2 }}
                      onClick={() => setEditingId(null)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {subcategory.title}
                    </Typography>
                    <Typography sx={{ mb: 2 }}>{subcategory.description}</Typography>
                    {subcategory.image && (
                      <img
                        src={`https://api.navinenterprises.in/${subcategory.image}`}
                        alt={subcategory.title}
                        style={{ width: '100%', height: 'auto', marginBottom: '16px' }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleEdit(subcategory)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, ml: 2 }}
                      onClick={() => handleDelete(subcategory._id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}

export default SubcategoryList;
