import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Contact from "../Screens/Contact/Contact";
import Products from "../Screens/Products/Products";
import VisionMission from "../Screens/AboutUs/VisionMission";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Login from "../Login/login";
import { Gallery } from "../Gallery/Gallery";
import OurProducts from "../Screens/Products/SubComps/OurProducts";
import About from "../Screens/AboutUs/About";
import ServicePage from "../Screens/Products/SubComps/ServicePage";
import GalleryPage from '../Forms/GalleryPage'
import AddCategory from '../Forms/AddCategory'
import AddSubcategory from '../Forms/AddSubcategory'
import SubcategoryPage from "../Screens/Home/SubComps/SubcategoryPage";



function Routers() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle login
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/About" element={<About />} />
        <Route path="/Aboutus" element={<VisionMission />} />
        <Route path="/subcategory" element={<OurProducts />} />
        <Route path="/category/:categoryId/subcategories" element={<SubcategoryPage />} />
        <Route path="/subcategory/:id" element={<ServicePage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/Login" element={<Login onLogin={handleLogin} />} />
        {/* Conditionally render routes accessible only when logged in */}
        {isLoggedIn ? (
          <>
            <Route path="/AddImageToGallery" element={<GalleryPage />} />
            <Route path="/AddMainCategory" element={<AddCategory />} />
            <Route path="/AddSubCategory" element={<AddSubcategory />} />

          </>
        ) : (
          // Redirect to login if user tries to access protected routes without logging in
          <>
            <Route path="/AddImageToGallery" element={<Navigate to="/Login" />} />
            <Route path="/AddMainCategory" element={<Navigate to="/Login" />} />
            <Route path="/AddSubCategory" element={<Navigate to="/Login" />} />

          </>
        )}
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routers;
