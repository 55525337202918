import React, { useState } from "react";
import "../Navbar/Navbar.css";
import { Link, NavLink } from 'react-router-dom';
import Hidden from "@mui/material/Hidden";
import CloseIcon from '@mui/icons-material/Close';
import LOGO from "../../Images/Circle_Round_Minimal_Initials_Photography_Logo_Video-1-1-removebg-preview.png"
import { Typography } from "@mui/material";
const Navbar = ({ isLoggedIn, onLogout }) => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleDrawer = () => {
    setShowLinks(!showLinks);
  };

  const closeDrawer = () => {
    setShowLinks(false);
  };

  const renderLoggedInLinks = () => (
    <>
     <li>
      <NavLink to="/AddImageToGallery" onClick={closeDrawer}>
      Gallery
      </NavLink>
      </li>
      <li>
      <NavLink to="/AddMainCategory" onClick={closeDrawer}>
      Main Category
      </NavLink>
      </li>
      <li>
      <NavLink to="/AddSubCategory" onClick={closeDrawer}>
      Sub Category   
         </NavLink>
      </li>
      <li>
      <button onClick={onLogout}>Logout</button>
      </li>
    </>
  );

  const renderLoggedOutLinks = () => (
    <>
      <li>
        <NavLink to="/" onClick={closeDrawer}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/Aboutus" onClick={closeDrawer}>
          About
        </NavLink>
      </li>
      <li>
        
        <NavLink to="/subcategory" onClick={closeDrawer}>
          Services
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact-us" onClick={closeDrawer}>
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink to="/gallery" onClick={closeDrawer}>
          Gallery
        </NavLink>
      </li>
    </>
  );

  return (
    <nav className="navbar">
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
         <img src={LOGO} alt="logo" height={"80px"}/>
         <Typography paddingLeft={"8px"} color={"white"} fontSize={"4vmin"} fontWeight={"bold"}>Navin Enterprises</Typography>
      </Link>
      <div className="nav-links-container">
        <ul className={showLinks ? "nav-links mobile" : "nav-links"}>
          {isLoggedIn ? renderLoggedInLinks() : renderLoggedOutLinks()}
        </ul>
      </div>

      <Hidden mdUp>
        {showLinks ? (
          <button className="toggle-button" onClick={toggleDrawer}>
            <CloseIcon />
          </button>
        ) : (
          <button className="toggle-button" onClick={toggleDrawer}>
            ☰
          </button>
        )}
      </Hidden>
    </nav>
  );
};

export default Navbar;
