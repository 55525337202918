import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem } from '@mui/material';
import axios from 'axios';
import SubcategoryList from './SubcategoryList';

function AddSubcategory() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [whatIs, setWhatIs] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.navinenterprises.in/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const subcategoryData = new FormData();
      subcategoryData.append('category', selectedCategory);
      subcategoryData.append('image', image);
      subcategoryData.append('title', title);
      subcategoryData.append('description', description);
      subcategoryData.append('whatIs', whatIs);
      subcategoryData.append('answer', answer);

      const response = await axios.post('https://api.navinenterprises.in/api/subcategories', subcategoryData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Subcategory added:', response.data);
      setSelectedCategory('');
      setImage('');
      setTitle('');
      setDescription('');
      setWhatIs('');
      setAnswer('');
    } catch (error) {
      console.error('Error adding subcategory:', error);
    }
  };

  return (
   <>
    <Box sx={{ padding: '100px' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Add New Subcategory</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          select
          label="Category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        >
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.title}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          component="label"
          sx={{ mb: 2 }}
        >
          Upload Image
          <input
            type="file"
            hidden
            onChange={(e) => setImage(e.target.files[0])}
          />
        </Button>
        <TextField
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <TextField
          label="What Is"
          variant="outlined"
          value={whatIs}
          onChange={(e) => setWhatIs(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Answer"
          variant="outlined"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary">Add Subcategory</Button>
      </form>
    </Box>
    <SubcategoryList/>
   </>
  );
}

export default AddSubcategory;
