import React from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Button, Grid,Hidden,Container } from "@mui/material";
import header from "../../../Images/metal.jpg";
import WhyUs from "./SubComps/WhyUs";
import image1 from "../../../Images/metal.jpg";
import image2 from "../../../Images/electroplating.jpg";
import About from "../AboutUs/About";
import { NavLink } from "react-router-dom";

// Dummy data for the three cards
const cardData = [
  {
    title: "Quality Assurance",
    description:
      "Our team is committed to providing the highest quality electroplating services, ensuring your products meet industry standards.",
  },
  {
    title: "Competitive Pricing",
    description:
      "We offer competitively priced services without compromising on quality, making us a preferred partner for many industries.",
  },
  {
    title: "Timely Delivery",
    description:
      "We understand the importance of meeting deadlines and ensure that your products are delivered on time, every time.",
  },
];

function Home() {
  return (
    <div style={{ overflow: "hidden", marginTop: "10vmin" }}>
      <Helmet>
        <title>Home</title>
      </Helmet>

      {/* Header Section */}
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px",
          color: "white",
          marginBottom: "-40px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${header})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.5,
            zIndex: -1,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: -1,
          },
        }}
      >
        <Typography sx={{ fontSize: "6vmin", fontWeight: "bold" }}>
          High-quality, competitively priced <br />
          electroplating services
        </Typography>
        <Box padding={{ sm: "10px 300px 20px 300px", xs: "10px 40px 20px 40px" }}>
          <hr />
        </Box>
        <Typography sx={{ fontSize: "4vmin" }}>
          Your trusted metal finishing partner for all your plating needs.
        </Typography>

        <Grid container justifyContent="center" spacing={2} mt={2}>
          <Grid item>
            <Button
              variant="outlined"
              sx={{
                borderColor: "white",
                color: "white",
                backgroundColor: "transparent",
                borderWidth: "2px",
                padding: "12px 24px",
                fontSize: "1.2rem",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderColor: "white",
                },
              }}
            ><NavLink
            to="/contact-us"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
              WRITE A QUOTE
              </NavLink>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={{
                borderColor: "white",
                color: "white",
                backgroundColor: "transparent",
                borderWidth: "2px",
                padding: "12px 24px",
                fontSize: "1.2rem",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderColor: "white",
                },
              }}
            >
              <NavLink
            to="/subcategory"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
              OUR SERVICES
              </NavLink>
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Why Us Section */}
      <WhyUs />

      {/* Three Cards Section */}
      <Box sx={{ padding: "60px 80px" }}>
        <Grid container spacing={4}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  textAlign: "left",
                  backgroundColor: "white",
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  border:'2px black solid',
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                  {card.title}
                </Typography>
                <Typography>{card.description}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
<About/>     
    </div>
  );
}

export default Home;
