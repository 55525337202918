import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Card, CardMedia, CardContent, IconButton } from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

function GalleryPage() {
  const [images, setImages] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.navinenterprises.in/api/getAllImages');
        setImages(response.data);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Error fetching images');
      }
    };

    fetchImages();
  }, []);

  const handleImageUpload = async () => {
    if (!imageFile) return;

    const formData = new FormData();
    formData.append('headerImage', imageFile);

    setUploading(true);
    try {
      await axios.post('https://api.navinenterprises.in/api/addImages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImageFile(null);
      // Refresh images
      const response = await axios.get('https://api.navinenterprises.in/api/getAllImages');
      setImages(response.data);
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Error uploading image');
    } finally {
      setUploading(false);
    }
  };

  const handleImageDelete = async (id) => {
    try {
      await axios.delete(`https://api.navinenterprises.in/api/images/${id}`);
      setImages(images.filter((image) => image._id !== id));
    } catch (err) {
      console.error('Error deleting image:', err);
      setError('Error deleting image');
    }
  };

  return (
    <Box sx={{ padding: '120px', backgroundColor: '#f4f4f4', minHeight: '100vh' }}>
      <Typography variant="h4" sx={{ mb: 2, color: 'gold' }}>
        Image Gallery
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Upload New Image
        </Typography>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files[0])}
          style={{ marginBottom: '10px' }}
        />
        <Button
          variant="contained"
          onClick={handleImageUpload}
          disabled={uploading}
          sx={{ backgroundColor: 'gold', color: 'black' }}
        >
          {uploading ? 'Uploading...' : 'Upload Image'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Box>

      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} key={image._id}>
            <Card sx={{ maxWidth: 345, position: 'relative' }}>
              <CardMedia
                component="img"
                alt={image.headerImage}
                height="140"
                image={`${image.headerImage}`} // Ensure the URL is correct
              />
              <CardContent>
                {/* Additional content can go here */}
              </CardContent>
              <Box sx={{ textAlign: 'center', padding: '10px' }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleImageDelete(image._id)}
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default GalleryPage;
